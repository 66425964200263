<template>
  <!--begin::Mixed Widget 18-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 my-lg-0">
      <div class="card-title">
        <div class="card-label font-weight-bolder">
          SOK Coins
          <PuSkeleton v-if="!this.loaded" />
        </div>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body Active-->
    <div v-if="this.balance > 0" class="card-body pt-0">
      <!--begin::Short Info-->
      <p class="mb-1">{{ $t('dashboard.coins.left') }}:</p>
      <h2 class="font-weight-bolder">
        <font-awesome-icon icon="coins" class="text-warning icon-md mr-1" />
        {{ $t('dashboard.coins.left_value', {deposit: new Intl.NumberFormat().format(this.balance)}) }}
      </h2>
      <router-link to="/services" class="btn btn-block btn-light-warning">{{ $t('dashboard.coins.spend') }}</router-link>
      <div v-if="this.firstLine" class="font-size-sm text-muted">
        <p></p>
        {{ $t('dashboard.coins.burn', { amount: new Intl.NumberFormat().format(this.firstLine.remain), date: this.firstLine.active_to.toLocaleDateString() }) }}<br />
        {{ $t('dashboard.coins.allGot', { amount: new Intl.NumberFormat().format(this.totalDeposit) }) }}<br />
        {{ $t('dashboard.coins.allSpent', { amount: new Intl.NumberFormat().format(this.totalSpent) }) }}
      </div>
      <!--end::Short Info-->
    </div>
    <!--end::Body Active-->
    <!--begin::Body InActive-->
    <div v-else class="card-body d-flex flex-column pt-0">
      <!--begin::Short Info-->
      <p v-html="$t('dashboard.coins.inactive')"></p>
      <div class="mt-auto">
        <!--
        @TODO Убрать комментарий когда будет готова страница "программа лояльности", на которую ведёт эта ссылка
        <router-link to="/coins" class="btn btn-block btn-light-warning">
          {{ $t('dashboard.coins.detail') }}
        </router-link> -->
      </div>
      <!--end::Short Info-->
    </div>
    <!--end::Body InActive-->
  </div>
  <!--end::Mixed Widget 18-->
</template>

<script>
import {mapGetters} from "vuex";

export default {
  async mounted() {
    await this.$store.dispatch('dashboard/fetchCoins')
    this.loaded = true
  },
  data() {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      balance: 'dashboard/coinsBalance',
      totalDeposit: 'dashboard/coinsTotalDeposit',
      totalSpent: 'dashboard/coinsTotalSpent',
      firstLine: 'dashboard/coinsFirstLine'
    }),
  }
}
</script>